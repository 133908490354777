<template>
  <div class="Quiz">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <title-text-box :title="title">
            <template slot="content">
              <div v-html="text" />
            </template>
          </title-text-box>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <questions-carousel
            :items="questions"
            cta-label-yes="SI"
            cta-label-no="NO"
            cta-go-to-products="VAI ALLA PAGINA DEI PRODOTTI"
          />
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TitleTextBox from '@components/title-text-box'
import QuestionsCarousel from '@components/questions-carousel'
export default {
  name:'Quiz',
  components: { TitleTextBox, QuestionsCarousel },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('quiz', ['questions', 'text', 'title']),
  },
  mounted(){
    this.$store.dispatch("setLoading", false);
  }


}
</script>

<style lang="scss" scoped>
@import "~$scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";
.Quiz{

}
</style>
